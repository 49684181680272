import React, { useMemo } from "react";
import "./index.css";
import GetDomain from "../../utils/GetDomain";
import GetButtonName from "../../utils/GetButtonName";
import { connect } from "react-redux";
import GetMarketName from "../../utils/GetMarketName";
import getOddsTermsText from "../../utils/getOddsTermsText";
var odds = require("odds-converter");

const BetBanner = (props) => {
  const betValue = useMemo(() => {
    if (props.apiType === "kambi") {
      return parseFloat(
        odds.american.toDecimal(props.bet.oddsAmerican)
      ).toFixed(2);
    } else {
      if (props.betType === "american") {
        return props.bet.oddsAmerican;
      } else if (props.betType === "fractional") {
        return props.bet.oddsFractional;
      } else {
        return props.bet.price;
      }
    }
  }, [props]);
  const isBetExpired = useMemo(
    () => new Date(props.bet?.market?.expiry) < new Date(),
    [JSON.stringify(props.bet)]
  );
  return (
    <div className="oddin-main">
      <div className="oddin-parent">
        <div
          className="oddin-main-title"
          style={{
            backgroundColor:
              props.lang === "sv_Mr_Green" || props.lang === "dk_Mr_Green" ? "#047c4c" : "#ee5d05",
          }}
        >
          {props.bannerTitle}
        </div>
        <div className="oddin-bet-title">
          <b>
            <p>{GetMarketName(props.bet.eventName, props.lang)}</p>
          </b>
          <p>
            {props.bet.title} - {props.bet.betName}
          </p>
        </div>
        <div className="oddin-bet-odd">{betValue}</div>
        <div
          className={`oddin-cta ${isBetExpired ? "disabled" : ""}`}
          style={{
            backgroundColor:
              props.lang === "sv_Mr_Green" || props.lang === "dk_Mr_Green" ? "#047c4c" : "#ee5d05",
          }}
        >
          {isBetExpired ? (
            GetButtonName(props.lang, isBetExpired)
          ) : (
            <a
              href={
                props.lang === "sv_Mr_Green"
                  ? `https://www.mrgreen.se/sport/deeplink/?sr=1919700&selections=[${props.bet.id}]`
                  : props.lang === "dk_Mr_Green"
                  ? `https://www.mrgreen.dk/sport/deeplink/?sr=1919700&selections=[${props.bet.id}]`
                  : `${GetDomain(props.lang)}${
                      props.apiType === "kambi"
                        ? `/#/event/1006715590?coupon=combination|${props.bet.id}|0|append`
                        : `[${props.bet.id}]`
                    }`
              }
              target="_blank"
              rel="noreferrer"
            >
              {GetButtonName(props.lang, isBetExpired)}
            </a>
          )}
        </div>
        <div className="oddin-tnc">{getOddsTermsText(props.lang)}</div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  apiType: state.bucket.apiType,
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(BetBanner);
