import React from "react";
import "./index.css";
import { connect } from "react-redux";
import getOddsTermsText from "../../utils/getOddsTermsText";
import GetButtonName from "../../utils/GetButtonName";
// import GetDomain from "../../utils/GetDomain";
var odds = require("odds-converter");
const ComboBetBanner = (props) => {
  const getTotalBet = () => {
    let totalBet = 1;
    if (props.apiType === "kambi") {
      props.items.map((item) => {
        return (totalBet *= parseFloat(
          odds.american.toDecimal(item?.oddsAmerican)
        ));
      });
    } else {
      props.items.map((item) => {
        return (totalBet *= parseFloat(item?.price));
      });
    }
    if (props.betType === "fractional") {
      const _bet = odds.decimal.toFractional(totalBet).simplify();
      return `${_bet.n}/${_bet.d}`;
    } else if (props.betType === "decimal") {
      return totalBet.toFixed(2);
    } else if (props.betType === "american") {
      const _americanBet = odds.decimal.toAmerican(totalBet);
      if (Number(_americanBet) > 0) {
        return `+${_americanBet}`;
      } else {
        return _americanBet;
      }
    }
  };
  return (
    <div className="card">
      <div className="parent">
        <div
          className="hedaer"
          style={{
            backgroundColor:
              props.lang === "sv_Mr_Green" || props.lang === "dk_Mr_Green" ? "#047c4c" : "#ee5d05",
          }}
        >
          {props.bannerTitle}
        </div>
        <div className="itemlist">
          {props.items.map((item, i) => (
            <div className="item">
              <b>
                <p>{item?.eventName}</p>
              </b>
              <p>
                {item?.title} - {item?.betName}
              </p>
            </div>
          ))}
        </div>
        <div className="summary">
          <div className="time">{getTotalBet()}</div>
          <div
            className="button"
            style={{
              marginRight: 50,
              backgroundColor:
                props.lang === "sv_Mr_Green" || props.lang === "dk_Mr_Green" ? "#047c4c" : "#ee5d05",
            }}
          >
            {GetButtonName(props.lang, false)}
          </div>
        </div>
        <div className="footer" style={{ paddingBottom: 40 }}>
          {getOddsTermsText(props.lang)}
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  items: state.bucket.items,
  apiType: state.bucket.apiType,
  spectateLanguage: state.spectate.language,
  betType: state.spectate.betType,
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(ComboBetBanner);
